<!--NavigationList.vue-->
<template>
    <div v-if="loggedIn" class="nav-items">
        <template v-for="(item, key) in navItems">
            <navigation-menu-item :key="key" :to="item.to || undefined" @click="item.click || undefined">
                {{ $t(item.label) }}
            </navigation-menu-item>
        </template>
        <template v-for="(item, key) in navDropdowns">
            <at-submenu :key="key" :title="$t(key)" class="growUiClass" mode="vertical">
                <template slot="title">{{ $t(key) }}</template>
                <template v-for="(val, itemKey) in item">
                    <navigation-menu-item :key="itemKey" :to="val.to || undefined" @click="val.click || undefined">
                        {{ $t(val.label) }}
                    </navigation-menu-item>
                </template>
            </at-submenu>
        </template>
    </div>
</template>

<script>
    import NavigationMenuItem from '@/components/NavigationMenuItem';

    export default {
        components: {
            NavigationMenuItem,
        },
        props: {
            modules: {
                type: Array,
                required: true,
            },
        },
        computed: {
            navItems() {
                const navItems = [];
                this.modules.forEach(m => {
                    const entries = m.getNavbarEntries();
                    entries.forEach(e => {
                        if (e.displayCondition(this.$store)) {
                            navItems.push(e.getData());
                        }
                    });
                });

                return navItems;
            },
            navDropdowns() {
                const entriesDr = {};
                this.modules.forEach(m => {
                    const entriesDropdown = m.getNavbarEntriesDropdown();
                    Object.keys(entriesDropdown).forEach(key => {
                        const isAllowItem = entriesDropdown[key][0].displayCondition(this.$store);
                        if (!entriesDr.hasOwnProperty(entriesDropdown[key][0].section) && isAllowItem) {
                            entriesDr[entriesDropdown[key][0].section] = [];
                        }
                        if (isAllowItem) {
                            entriesDr[entriesDropdown[key][0].section].push(entriesDropdown[key][0]);
                        }
                    });
                });

                return entriesDr;
            },
            loggedIn() {
                return this.$store.getters['user/loggedIn'];
            },
        },
    };
</script>
